import styled from 'styled-components';

interface ContainerProps {
  focus: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: white;
  border-radius: 10px;
  padding: 16px;
  width: 100%;
  border: 0.2rem solid #740000;
  color: #666360;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;

  &:focus > input {
    border: 2px solid #740000;
    display: none;
  }

  & + div {
    margin-top: 8px;
  }

  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: rgba(0, 0, 0, 0.8);
    outline: none;
    width: 100%;
    font-size: 1.6rem;

    &::placeholder {
      color: #666360;
    }
  }

  svg {
    margin-right: 16px;
    color: #740000;
  }
`;
