import { AxiosResponse } from 'axios';
import { format, parseISO } from 'date-fns';
import { ProfileRoleEnum } from '../../../models/Appointment';
import { IUser } from '../../../models/User';
import { api, RawHTTPResponse, getAuthHeader } from '../api';
import useApiCall from '../../../hooks/apiCall';

interface UpdateUser {
  Request: {
    user: {
      id: string;
      name: string;
      email: string;
      profile_role: ProfileRoleEnum;
      password?: string;
    };
  };
  Response: IUser;
}

export const useUpdateUser = () =>
  useApiCall<UpdateUser['Request'], UpdateUser['Response']>({
    request: async ({ user }, token) => {
      const { data }: RawHTTPResponse<UpdateUser['Response']> = await api.put(
        '/profile',
        user,
        getAuthHeader(token),
      );
      return data;
    },
    errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível atualizar o usuário',
    },
  });

interface CreateUser {
  Request: {
    user: {
      name: string;
      email: string;
      profile_role: ProfileRoleEnum;
      password: string;
    };

    imageFile: File;
  };
  Response: IUser;
}

export const useCreateUser = () =>
  useApiCall<CreateUser['Request'], CreateUser['Response']>({
    request: async ({ user, imageFile }, token) => {
      const formData = new FormData();
      formData.append('name', user.name);
      formData.append('email', user.email);
      formData.append('profile_role', user.profile_role);
      formData.append('password', user.password);

      if (imageFile.name.length > 0) {
        formData.append('avatar', imageFile);
      }
      const { data }: RawHTTPResponse<CreateUser['Response']> = await api.post(
        '/users',
        formData,
        getAuthHeader(token),
      );
      return data;
    },
    errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível criar o usuário',
    },
  });
interface GetUsersList {
  Request: {};
  Response: IUser[];
}

export const useGetUsersList = () =>
  useApiCall<GetUsersList['Request'], GetUsersList['Response']>({
    // eslint-disable-next-line no-empty-pattern
    request: async ({}, token) => {
      const { data }: RawHTTPResponse<GetUsersList['Response']> = await api.get(
        '/profile/all',
        getAuthHeader(token),
      );
      return data;
    },
    errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível buscar os agendamentos',
    },
  });
