/* eslint-disable import/no-duplicates */
import React, { useEffect, useMemo, useState } from 'react';
import DayPicker from 'react-day-picker';
import { BiSearch } from 'react-icons/bi';
import { HiOutlinePencil } from 'react-icons/hi';
import { IoIosCloseCircle } from 'react-icons/io';
import { IoEyeSharp } from 'react-icons/io5';
import { FiPlus } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import {
  addDays,
  format,
  isThisMonth,
  isToday,
  isTomorrow,
  setDate,
  setHours,
  setMinutes,
  setSeconds,
} from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { ptBR } from 'date-fns/locale';
import {
  Row,
  Calendar,
  Schedule,
  Container,
  Section,
  Details,
  ContentDetails,
  ContentRowDetails,
} from './styles';
import Appointment from './components/Appointment';
import AppointmentItem from './components/AppointmentItem';
import AppointmentDayPicker from '../../../../components/AppointmentDayPicker';
import {
  AppointmentStatusEnum,
  CertificateEnum,
  DocumentTypeEnum,
  EmissionTypeEnum,
  IAppointment,
  IMonthDayStatus,
  ProfileRoleEnum,
  PaymentTypeEnum,
} from '../../../../models/Appointment';
import {
  useGetMonthAppointmentsStatus,
  useGetDayAppointments,
  useUpdateAppointmentStatus,
} from '../../../../services/api/appointments';
import { IUser } from '../../../../models/User';
import useDebouncedText from '../../../../hooks/debouncedText';
import { getCurrencyMask } from '../../../../utils/currencyFormatter';

const setDayMoment = (hours: number, minutes = 0, day: Date = new Date()) => {
  return setSeconds(setMinutes(setHours(day, hours), minutes), 0);
};

const Scheduling: React.FC = () => {
  const history = useHistory();

  const getMonthAppointmentsStatus = useGetMonthAppointmentsStatus();
  const getDayAppointments = useGetDayAppointments();
  const updateAppointmentStatus = useUpdateAppointmentStatus();

  const [loading, setLoading] = useState<boolean>(true);
  const [isMonthLoading, setIsMonthLoading] = useState<boolean>(false);
  const [searchFilter, setSearchFilter] = useDebouncedText();
  const [selectedDay, setSelectedDay] = useState<Date>(addDays(new Date(), 0));
  const [selectedMonth, setSelectedMonth] = useState<Date>(
    addDays(new Date(), 0),
  );
  const [appointments, setAppointments] = useState<IAppointment[]>([]);
  const [selectedAppointment, setSelectedAppointment] = useState<
    IAppointment | undefined
  >(undefined);
  const [monthStatus, setMonthStatus] = useState<IMonthDayStatus[]>([]);

  const handleAppointmentStatusUpdate = async (
    status: AppointmentStatusEnum,
  ) => {
    if (selectedAppointment?.id) {
      const updatedAppointment = await updateAppointmentStatus({
        id: selectedAppointment?.id,
        status,
      });
      console.log(
        '🚀 ~ file: index.tsx ~ line 309 ~ updatedAppointment',
        updatedAppointment,
      );

      setAppointments(apts =>
        apts.map(apt => {
          if (apt.id === selectedAppointment.id) {
            return {
              ...apt,
              status,
            };
          }
          return apt;
        }),
      );
      setSelectedAppointment({
        ...selectedAppointment,
        status,
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  useEffect(() => {
    (async () => {
      setIsMonthLoading(true);

      try {
        const month = await getMonthAppointmentsStatus({
          date: selectedMonth,
        });
        setMonthStatus(month);
        setSelectedDay(
          isThisMonth(selectedMonth) ? new Date() : setDate(selectedMonth, 1),
        );
        setSelectedAppointment(undefined);
      } catch (error) {
      } finally {
        setIsMonthLoading(false);
      }

      /* setMonthStatus(
        new Array(lastDayOfMonth(selectedMonth).getDate())
          .fill(false)
          .map((d, i) => ({
            month_day: i + 1,
            // available: Boolean(Math.round(Math.random())),
            available: new Array(Math.round(Math.random() * 4))
              .fill(false)
              .map(a =>
                setDayMoment(
                  Math.round(Math.random() * 11) + 8,
                  Math.round(Math.random()) * 30,
                ),
              ),
          })),
      );

      setIsMonthLoading(false); */
    })();
  }, [selectedMonth]);

  useEffect(() => {
    (async () => {
      try {
        const apts = await getDayAppointments({
          date: setDayMoment(0, 0, selectedDay),
          filter_name: searchFilter,
        });
        setAppointments(apts);
        setSelectedAppointment(undefined);
      } catch (error) {}
    })();
  }, [selectedDay]);

  useEffect(() => {
    (async () => {
      try {
        const apts = await getDayAppointments({
          date: setDayMoment(0, 0, selectedDay),
          filter_name: searchFilter,
        });
        setAppointments(apts);
        setSelectedAppointment(undefined);
      } catch (error) {}
    })();
  }, [searchFilter]);

  const selectAppointment = (id: string) => {
    const selectedApt = appointments.filter(apt => apt.id === id)[0];
    setSelectedAppointment(currentApt =>
      currentApt === selectedApt ? undefined : selectedApt,
    );
  };

  const [
    morningAppointments,
    eveningAppointments,
    nightAppointments,
  ] = useMemo(() => {
    const sortedApts = appointments.sort(
      (a, b) => a.date.getTime() - b.date.getTime(),
    );

    return [
      sortedApts.filter(apt => apt.date.getHours() < 12),
      sortedApts.filter(
        apt => apt.date.getHours() >= 12 && apt.date.getHours() < 18,
      ),
      sortedApts.filter(apt => apt.date.getHours() >= 18),
    ];
  }, [appointments]);

  const fullDays = useMemo(
    () =>
      monthStatus
        .filter(d => d.available.length === 0)
        .map(d => {
          const dd = new Date(selectedMonth.getTime());
          dd.setDate(d.month_day);
          return dd;
        }),
    [monthStatus],
  );

  return (
    <Container>
      <Row>
        <Schedule>
          <h1>
            {loading ? (
              <Skeleton duration={2} height={30} />
            ) : (
              'Horários agendados'
            )}
          </h1>
          {loading ? (
            <Skeleton duration={2} height={30} style={{ marginTop: 10 }} />
          ) : (
            <p>
              {(() => {
                if (isToday(selectedDay)) return <span>Hoje</span>;
                if (isTomorrow(selectedDay)) return <span>Amanhã</span>;
                return null;
              })()}
              <span>{`Dia ${format(selectedDay, 'dd')}`}</span>
              <span style={{ textTransform: 'capitalize' }}>
                {format(selectedDay, 'EEEE', { locale: ptBR })}
              </span>
            </p>
          )}
          <section>
            {loading ? (
              <Skeleton
                duration={2}
                height={30}
                style={{ margin: '30px 0 -10px 0' }}
              />
            ) : (
              <>
                <Section>
                  <Link to="/appointments/schedule">
                    <FiPlus />
                  </Link>
                  <div className="main-search-div">
                    {/* <button type="button">Curitiba</button>
                    <button type="button">Bigorrilho</button> */}

                    <div className="search-input">
                      <input
                        type="text"
                        placeholder="Buscar"
                        onChange={e => setSearchFilter(e.target.value)}
                      />
                      <BiSearch />
                    </div>
                  </div>
                </Section>
              </>
            )}
            {loading ? (
              <Skeleton duration={2} height={55} style={{ marginTop: 30 }} />
            ) : (
              <Appointment name={`Manhã (${morningAppointments.length})`}>
                <>
                  {morningAppointments.length ? (
                    morningAppointments.map(apt => (
                      <AppointmentItem
                        key={apt.id}
                        id={apt.id}
                        name={apt.appointment_details.name}
                        hour={apt.date}
                        selectAppointmentItem={selectAppointment}
                      />
                    ))
                  ) : (
                    <p>Sem agendamentos</p>
                  )}
                </>
              </Appointment>
            )}

            {loading ? (
              <Skeleton duration={2} height={55} style={{ marginTop: 30 }} />
            ) : (
              <Appointment name={`Tarde (${eveningAppointments.length})`}>
                <>
                  {eveningAppointments.length ? (
                    eveningAppointments.map(apt => (
                      <AppointmentItem
                        key={apt.id}
                        id={apt.id}
                        name={apt.appointment_details.name}
                        hour={apt.date}
                        selectAppointmentItem={selectAppointment}
                      />
                    ))
                  ) : (
                    <p>Sem agendamentos</p>
                  )}
                </>
              </Appointment>
            )}

            {loading ? (
              <Skeleton duration={2} height={55} style={{ marginTop: 30 }} />
            ) : (
              <Appointment name={`Noite (${nightAppointments.length})`}>
                <>
                  {nightAppointments.length ? (
                    nightAppointments.map(apt => (
                      <AppointmentItem
                        key={apt.id}
                        id={apt.id}
                        name={apt.appointment_details.name}
                        hour={apt.date}
                        selectAppointmentItem={selectAppointment}
                      />
                    ))
                  ) : (
                    <p>Sem agendamentos</p>
                  )}
                </>
              </Appointment>
            )}
          </section>
        </Schedule>
        <Calendar>
          {isMonthLoading ? (
            <Skeleton duration={2} height={360} />
          ) : (
            <AppointmentDayPicker
              fromMonth={new Date()}
              onChangeDay={date => setSelectedDay(date)}
              onChangeMonth={date => setSelectedMonth(date)}
              currentMonth={selectedMonth}
              currentDay={selectedDay}
              unavailableDays={fullDays}
            />
          )}
          <Details show={!!selectedAppointment}>
            <header>
              <h4>Detalhes do Agendamento</h4>
              <IoIosCloseCircle
                onClick={() => setSelectedAppointment(undefined)}
              />
            </header>
            <ContentDetails>
              <h5>
                {selectedAppointment?.appointment_details.name}
                {' - '}
                {selectedAppointment?.appointment_details.phone}
              </h5>
              <h5>{selectedAppointment?.appointment_details.email}</h5>
              <p>
                {format(
                  selectedAppointment?.date || new Date(),
                  "HH'h':mm - dd/MM/yy",
                )}
              </p>
              <section>
                <ContentRowDetails>
                  <div className="tags-container">
                    <div className="top-div">
                      <input
                        type="text"
                        disabled
                        value={
                          selectedAppointment?.appointment_details
                            .certificate_type
                        }
                      />
                      <input
                        type="text"
                        disabled
                        value={
                          selectedAppointment?.appointment_details.emission_type
                        }
                      />
                    </div>

                    <div className="bottom-div">
                      <input
                        type="text"
                        disabled
                        value={
                          selectedAppointment?.appointment_details.payment_type
                        }
                      />
                      <input
                        type="text"
                        disabled
                        value={getCurrencyMask(
                          selectedAppointment?.appointment_details.value || 0,
                        )}
                      />
                      <input
                        type="text"
                        disabled
                        className="short-tag"
                        value={
                          selectedAppointment?.appointment_details.document_type
                        }
                      />
                    </div>
                  </div>

                  <section>
                    {(() => {
                      switch (selectedAppointment?.status) {
                        case AppointmentStatusEnum.Pending:
                          return (
                            <>
                              <button
                                type="button"
                                className="btn btn--success"
                                onClick={() =>
                                  handleAppointmentStatusUpdate(
                                    AppointmentStatusEnum.Done,
                                  )
                                }
                              >
                                Realizado
                              </button>
                              <button
                                type="button"
                                className="btn btn--canceled"
                                onClick={() =>
                                  handleAppointmentStatusUpdate(
                                    AppointmentStatusEnum.Canceled,
                                  )
                                }
                              >
                                Cancelar
                              </button>
                            </>
                          );
                        case AppointmentStatusEnum.Done:
                          return (
                            <button
                              type="button"
                              className="btn btn--success"
                              disabled
                            >
                              Realizado
                            </button>
                          );
                        case AppointmentStatusEnum.Canceled:
                          return (
                            <button
                              type="button"
                              className="btn btn--canceled"
                              disabled
                            >
                              Cancelado
                            </button>
                          );
                        default:
                          return (
                            <p>
                              Estado inválido. Fale com a equipe de
                              desenvolvimento.
                            </p>
                          );
                      }
                    })()}
                    {selectedAppointment?.status === 'Pendente' && (
                      <button
                        type="button"
                        className="edit-button"
                        onClick={() =>
                          history.push('/appointments/schedule', {
                            appointment: selectedAppointment,
                          })
                        }
                      >
                        <HiOutlinePencil />
                      </button>
                    )}
                  </section>
                </ContentRowDetails>
                <ContentRowDetails>
                  {/* <button type="button">
                    <IoEyeSharp />
                  </button> */}
                </ContentRowDetails>
              </section>
            </ContentDetails>
          </Details>
        </Calendar>
      </Row>
    </Container>
  );
};

export default Scheduling;
