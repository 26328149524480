import styled from 'styled-components';

interface ContainerProps {
  open: boolean;
}

export const Container = styled.div<ContainerProps>`
  max-width: 250px;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: ${props => (props.open ? '0' : '-250px')};
  transition: all 0.3s ease;
  z-index: 200;
  background: #272525;
`;

export const Content = styled.div`
  margin: 0 30px;
  margin-top: 130px;
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    line-height: 22px;
    font-size: 16px;

    span {
      color: #f4ede8;
    }

    // a {
    strong {
      text-decoration: none;
      color: #ff9000;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const LinkContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;

  button {
    background: transparent;
    text-align: left;
    border: none;
    font-size: 17px;
    margin-top: 20px;
    color: rgba(255, 255, 255, 0.8);

    &:hover {
      cursor: pointer;
    }
  }

  a {
    margin-top: 20px;
    text-decoration: none;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.8);
  }

  .white {
    color: rgba(255, 255, 255, 1);
  }
`;
