import styled from 'styled-components';

export const Container = styled.div`
  max-width: 80rem;
  margin: 0 auto;
  margin-top: 14rem;
  margin-bottom: 6rem;
  @media (max-width: 900px) {
    margin: 0 3rem;
    margin-top: 14rem;
  }
`;

export const Row = styled.div`
  display: flex;
  @media (max-width: 850px) {
    display: grid;
    grid-template-columns: repeat(1, 2fr);
    max-width: 45rem;
    width: 100%;
    margin: 0 auto;
  }
`;

export const Schedule = styled.div`
  flex: 2;
  margin-right: 4.5rem;
  @media (max-width: 850px) {
    margin-right: 0;
  }
  h1 {
    font-size: 2.7rem;
    color: black;
    font-weight: 800;
  }
  p {
    margin-top: 8px;
    color: black;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.4rem;
    span {
      display: flex;
      align-items: center;
    }
    span + span::before {
      content: '';
      width: 1px;
      height: 12px;
      background: black;
      margin: 0 8px;
    }
  }
`;

export const Section = styled.div`
  margin-bottom: -10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .main-search-div {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 1rem;

    button {
      background-color: #c4c4c4;
      cursor: pointer;
      width: 50%;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 0.5rem;
      outline: none;
      border: none;
      font-weight: 500;
      font-size: 1.4rem;
      color: black;

      :focus {
        border: 0.2rem solid black;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
    .search-input {
      display: flex;
      width: 100%;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;

      input {
        border: none;
        padding: 5px 10px;
        padding-right: 35px;
        color: black;
        background: rgba(196, 196, 196, 0.1);
        font-size: 16px;
        outline: none;
        width: 100%;
      }
      svg {
        font-size: 20px;
        margin-top: 7px;
        margin-left: -30px;
      }
    }
  }

  a {
    border: none;
    outline: none;
    cursor: pointer;
    margin-left: 90%;
    margin-bottom: 1rem;
    background: #c4c4c4;
    border-radius: 5px;
    width: 10%;
    padding: 4px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 25px;
      color: black;
      margin: 0 auto;
    }
  }
`;

export const Calendar = styled.aside`
  max-width: 400px;
  width: 100%;
  @media (max-width: 850px) {
    max-width: 100%;
    margin-top: 40px;
  }

  .DayPicker-Day--selected {
    background: #740000, 100% !important;
    border-radius: 10px;
    color: #232129 !important;
  }
  @media (max-width: 430px) {
    .DayPicker-Day--selected,
    .DayPicker-Day--disabled,
    .DayPicker-Day--today,
    .DayPicker-Day--available:not(.DayPicker-Day--outside) {
      font-size: 12px;
    }
  }
  @media (max-width: 360px) {
    .DayPicker-Day--selected,
    .DayPicker-Day--disabled,
    .DayPicker-Day--today,
    .DayPicker-Day--available:not(.DayPicker-Day--outside) {
      font-size: 11px;
    }
  }
`;

interface DetailsProps {
  show: boolean;
}

export const Details = styled.div<DetailsProps>`
  display: ${props => (props.show ? 'block' : 'none')};
  max-width: 400px;
  width: 100%;
  margin-top: 20px;
  background: #c4c4c4;
  border: 0.1rem solid black;
  border-radius: 0.5rem;

  header {
    width: 100%;
    padding: 20px;
    text-align: right;
    position: relative;
    background-color: #740000;
    border-bottom: 2px solid rgba(0, 0, 0, 0.3);
    h4 {
      text-align: center;
      color: white;
      font-size: 18px;
      font-weight: normal;
      @media (max-width: 420px) {
        margin-top: 35px;
      }
    }
    svg {
      position: absolute;
      top: 18px;
      right: 15px;
      cursor: pointer;
      color: white;
      font-size: 30px;
    }
  }
  @media (max-width: 850px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

export const ContentDetails = styled.div`
  margin: 0 30px;
  margin-top: 15px;
  border-color: black;
  border-radius: 5px;
  h5 {
    color: black;
    font-size: 16px;
    font-weight: 800;
    margin-top: 5px;
  }
  p {
    margin-top: 10px;
    font-size: 17px;
    color: black;
  }
  section {
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    padding-bottom: 5px;
  }
`;

export const ContentRowDetails = styled.div`
  flex: 3;
  flex-wrap: wrap-reverse;
  .details {
    width: 100%;
    background-color: #2e2b2b;
    color: #fff;
    border-radius: 5px;
    border: none;
    text-align: center;
    outline: none;
    font-size: 1.6rem;
  }
  section {
    display: flex;
    gap: 1rem;
    .btn--canceled {
      background: #e50000;
    }
    button {
      color: rgba(255, 255, 255, 0.8);
      border: none;
      background: #007047;
      border-radius: 5px;
      text-align: center;
      padding: 0.5rem 0;
      outline: none;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
      cursor: pointer;
      width: 12.5rem;
      height: 3.6rem;
      align-self: center;
      font-size: 16px;
      font-weight: bold;

      &:nth-child(2) {
        background: #e50000;
      }
    }
    .edit-button {
      margin-left: auto;
      color: white;
      border: none;
      background: #2e2b2b;
      border-radius: 5px;
      text-align: center;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
      width: 6.5rem;
      height: 3.6rem;
      cursor: pointer;

      svg {
        font-size: 22px;
        color: #fff;
        margin-top: 3px;
        /* #740000, 100% */
      }
    }
  }
  &:nth-child(2) {
    margin-right: 0;
    margin-top: -60px;
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .tags-container {
    display: flex;
    flex-flow: row wrap;

    div {
      display: flex;
      margin-bottom: 1rem;
      width: 33.5rem;
      input {
        color: white;
        border: none;
        width: 12.5rem;
        background: #740000;
        border-radius: 5px;
        text-align: center;
        outline: none;
        font-size: 1.6rem;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
      }
      .short-tag {
        width: 6.5rem;
      }
    }

    .top-div {
      input {
        margin-right: 1rem;
      }
    }

    .bottom-div {
      justify-content: space-between;
    }
  }
`;
