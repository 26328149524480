import styled from 'styled-components';

export const Container = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100vw;
  max-height: 100vh;
  display: flex;
  .avatar-container {
    min-width: 25rem;
    max-width: 25rem;
    margin-top: 2rem;
    max-height: 30rem;
    margin-right: 8rem;

    cursor: pointer;
    text-align: center;
    label {
      cursor: pointer;
    }
  }
  .avatar {
    height: 25rem;
    width: 25rem;
    border-radius: 100%;
    object-fit: cover;
  }

  div {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    min-height: 8rem;

    input {
      background: #c4c4c4;
      border: none;
      border-radius: 5px;
      padding: 12px 18px;
      color: black;
      outline: none;
      font-size: 14px;
      font-weight: bold;
      width: 100%;
      max-width: 280px;
    }

    label {
      color: black;
      display: block;
      font-weight: bold;
      font-size: 19px;
    }

    section {
      display: flex;
      justify-content: space-between;
      max-width: 28rem;
      margin-top: 2rem;
      button {
        color: rgba(255, 255, 255, 0.8);
        border: none;
        background: #007047;
        border-radius: 5px;
        text-align: center;
        padding: 0.5rem 0;
        outline: none;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        cursor: pointer;
        width: 12.5rem;
        height: 3.6rem;
        align-self: center;
        font-size: 16px;
        font-weight: bold;

        &:nth-child(2) {
          background: #e50000;
        }
      }
    }
  }
`;

interface IInputPhotoProps {
  imgSource: string;
}
export const InputPhoto = styled.div<IInputPhotoProps>`
  width: 23.2rem;
  @media (max-width: 500px) {
    min-width: 100%;
  }
  .background-image {
    background: url(${props => props.imgSource}), var(--black-background);
    background-size: cover;
    border: 1px solid var(--green-primary);
    border-radius: 1rem;
    color: #fff;

    min-width: 100%;
    height: 18rem;
    position: relative;
    outline: none;
    cursor: pointer;
    &:hover,
    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px var(--green-primary);
    }
  }
`;
