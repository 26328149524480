import styled from 'styled-components';

interface ContentRowButtonProps {
  click: boolean;
}

export const ContentRowButton = styled.button<ContentRowButtonProps>`
  max-width: 65px;
  width: 100%;
  border: none;
  background: ${props => (props.click ? '#740000' : '#c4c4c4')};
  border-radius: 5px;
  text-align: center;
  padding: 13px;
  outline: none;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: ${props => (props.click ? 'white' : 'black')};
  transition: all 0.2s ease;
  label {
    cursor: pointer;
  }

  @media (max-width: 450px) {
    font-size: 15px;
    max-width: 55px;
    padding: 10px;
  }
`;
