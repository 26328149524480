import styled from 'styled-components';

interface ContainerProps {
  isLoading: number;
  background: string;
  textColor: string;
}

export const Container = styled.button<ContainerProps>`
  background: ${({ background }) => background || 'transparent'};
  font-size: 1.6rem;
  border-radius: 10px;
  border: 1px solid #fff;
  padding: 1.6rem;
  color: ${({ textColor }) => textColor || 'white'};
  width: 100%;
  font-weight: 800;
  outline: none;
  margin-top: 2.4rem;
  transition: background-color 0.3s;
  cursor: ${({ isLoading }) => (isLoading ? 'not-allowed' : 'pointer')};
`;
