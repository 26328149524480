import React from 'react';
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom';
import { Container, Profile, Content, LinkContainer } from './styles';
import profile from '../../../images/header/profile.png';
import { useAuth } from '../../../hooks/auth';
import { ProfileRoleEnum } from '../../../models/Appointment';

type Props = RouteComponentProps & {
  setOpenSideBar: (open: boolean) => void;
  open: boolean;
};

const SideBar: React.FC<Props> = ({
  open,
  location: { pathname },
  setOpenSideBar,
}: Props) => {
  const { signOut, user } = useAuth();

  return (
    <Container open={open}>
      <Content>
        <Profile>
          <img src={profile} alt="Alê Osorio" />
          <div>
            <span>Bem-vindo,</span>
            {/* <NavLink to="/profile"> */}
            <strong>{user?.name || 'Sem usuário logado'}</strong>
            {/* </NavLink> */}
          </div>
        </Profile>
        <LinkContainer>
          <NavLink
            to="/appointments"
            onClick={() => setOpenSideBar(!open)}
            className={pathname === '/appointments' ? 'white' : ''}
          >
            Agendamento
          </NavLink>
          {user?.profile_role === ProfileRoleEnum.ADMIN ? (
            <NavLink
              to="/users"
              onClick={() => setOpenSideBar(!open)}
              className={pathname === '/users' ? 'white' : ''}
            >
              Usuários
            </NavLink>
          ) : (
            <></>
          )}
          <button type="button" onClick={signOut}>
            Sair
          </button>
        </LinkContainer>
      </Content>
    </Container>
  );
};

export default withRouter(SideBar);
