import React, { InputHTMLAttributes, useState } from 'react';

import { IconBaseProps } from 'react-icons';

import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  containerStyle?: React.CSSProperties;
  icon?: React.ComponentType<IconBaseProps>;
}

const Input: React.FC<InputProps> = ({
  name,
  containerStyle = {},
  icon: Icon,
  ...rest
}: InputProps) => {
  const [focus, setFocus] = useState<boolean>(false);

  return (
    <Container
      style={containerStyle}
      data-testid="input-container"
      focus={focus}
    >
      {Icon && <Icon size={20} />}
      <input
        {...rest}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        name={name}
      />
    </Container>
  );
};

export default Input;
