import React, { ReactNode, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiPlus } from 'react-icons/fi';
import Skeleton from 'react-loading-skeleton';
import DropdownList from '../../../../components/DropdownList';
import { useToast } from '../../../../hooks/toast';
import { ProfileRoleEnum } from '../../../../models/Appointment';
import { IUser } from '../../../../models/User';
import defaultAvatar from '../../../../images/header/profile.png';

import { useGetUsersList, useUpdateUser } from '../../../../services/api/users';

import { Container, UserComponent } from './styles';
import { useAuth } from '../../../../hooks/auth';

const ListUsers: React.FC = () => {
  const getUsersList = useGetUsersList();
  const updateUser = useUpdateUser();
  const history = useHistory();

  const loggedUser = useAuth().user;

  const [loading, setLoading] = useState<boolean>(true);

  const { addToast } = useToast();

  const [profile_role, setProfileRole] = useState(ProfileRoleEnum.ADMIN);
  const [userList, setUserList] = useState<IUser[]>();

  const updateArray = async (user: IUser) => {
    if (userList) {
      const elementsIndex = userList.findIndex(
        element => element.id === user.id,
      );
      const newList = [...userList];
      newList[elementsIndex] = {
        ...newList[elementsIndex],
        profile_role: user.profile_role,
      };
      const previousList = userList;
      try {
        await updateUser({
          user: {
            email: newList[elementsIndex].email,
            id: newList[elementsIndex].id,
            name: newList[elementsIndex].name,
            profile_role: newList[elementsIndex].profile_role,
          },
        });
        setUserList(newList);
        addToast({
          type: 'success',
          title: 'Os dados do usuário foram atualizados com sucesso',
        });
      } catch {
        setUserList(previousList);
      }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await getUsersList({});
        setUserList(response);
        setLoading(false);
      } catch (error) {
        addToast({
          type: 'error',
          title:
            'Não conseguimos carregar a lista de usuários, tente novamente mais tarde',
        });
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Container>
      <div className="create-button">
        <Link to="/users/create">
          <FiPlus height="100px" width="100px" />
        </Link>
      </div>
      {loading ? (
        <Skeleton
          duration={2}
          height={70}
          style={{ margin: '1rem auto', width: '90rem', display: 'flex' }}
        />
      ) : (
        <>
          {userList ? (
            userList.map((data, i) => (
              <UserComponent key={data.id}>
                <img src={data.avatar || defaultAvatar} />
                <span
                  onClick={() =>
                    history.push('/users/edit', {
                      oldUser: data,
                    })
                  }
                >
                  {data.name}
                </span>
                <DropdownList
                  className="dropdown"
                  defaultValue={data.profile_role}
                  disabled={loggedUser.id === data.id}
                  value={
                    [...Object.values(ProfileRoleEnum)].includes(profile_role)
                      ? data.profile_role
                      : 'NULL'
                  }
                  onChange={e =>
                    updateArray({
                      ...data,
                      profile_role: e.target.value as ProfileRoleEnum,
                    })
                  }
                >
                  <option value="NULL" disabled>
                    Selecione
                  </option>
                  {Object.values(ProfileRoleEnum).map(v => (
                    <option key={v} value={v}>
                      {v}
                    </option>
                  ))}
                </DropdownList>
              </UserComponent>
            ))
          ) : (
            <p>Sem usuários</p>
          )}
        </>
      )}
    </Container>
  );
};

export default ListUsers;
