import styled from 'styled-components';
import { darken } from 'polished';
import DayPicker from 'react-day-picker';

import setaDireita from '../../images/seta-direita.svg';
import setaEsquerda from '../../images/seta-esquerda.svg';

export const StyledDayPicker = styled(DayPicker)`
  //.DayPicker {
  background: #c4c4c4;
  border-radius: 5px;
  width: 100%;
  border: 0.2rem solid black;

  //}
  .DayPicker-wrapper {
    padding: 1.5rem;
  }
  .DayPicker-NavBar {
    background-color: #740000;
    margin: -1.5rem;
    height: 6rem;
    min-width: 100%;
    border-bottom: 0.2rem solid black;
    @media (max-width: 850px) {
      min-width: 100%;
    }
  }
  .DayPicker-NavButton--prev {
    margin-right: 2rem;
    width: 3rem;
    height: 3rem;
    background-image: url(${setaEsquerda});
  }

  .DayPicker-NavButton--next {
    width: 3rem;
    height: 3rem;
    background-image: url(${setaDireita});
  }
  .DayPicker-Caption {
    text-align: center;
    font-size: 1.5rem;
    color: #fff;
    margin-top: -4.5rem;
  }
  .DayPicker-Weekdays {
    border-top: 20px solid rgba(0, 0, 0, 0.7);
  }
  .DayPicker-Weekday {
    color: #232129;
    font-size: 1.4rem;
    /* border-top: 20px solid rgba(0, 0, 0, 0.7); */
  }
  .DayPicker,
  .DayPicker-Month {
    width: 100%;
  }
  .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 8px;
    margin: 16px;
  }
  .DayPicker-Day {
    width: 40px;
    height: 40px;
    font-size: 1.5rem;

    transition: all 0.2s;
  }
  .DayPicker-Day--available:not(.DayPicker-Day--outside) {
    background: #2e2b2b;
    border-radius: 10px;
    color: #fff;
  }

  .DayPicker-Day--today {
    font-weight: normal;
    color: #111 !important;
    background-color: #fff !important;
  }
  .DayPicker-Day--highlighted {
    font-weight: normal;
    color: #fff !important;
    background-color: #740000 !important;
  }
  .DayPicker-Day--disabled {
    color: #232129 !important;
    background: transparent !important;
  }

  &:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: #ed9000 !important;
    color: #111 !important;
  }

  .DayPicker-Day--outside {
    color: #111;
    cursor: default;
    background: transparent !important;
  }
  @media (max-width: 430px) {
    .DayPicker-Day--selected,
    .DayPicker-Day--disabled,
    .DayPicker-Day--today,
    .DayPicker-Day--available:not(.DayPicker-Day--outside) {
      font-size: 12px;
    }
  }
  @media (max-width: 360px) {
    .DayPicker-Day--selected,
    .DayPicker-Day--disabled,
    .DayPicker-Day--today,
    .DayPicker-Day--available:not(.DayPicker-Day--outside) {
      font-size: 11px;
    }
  }
`;
