import styled from 'styled-components';

export const Container = styled.div`
  max-width: 900px;
  margin: 0 auto;
  margin-top: 140px;
  margin-bottom: 60px;

  @media (max-width: 1000px) {
    margin: 0 30px;
    margin-top: 140px;
  }
`;

export const Row = styled.div`
  display: flex;

  @media (max-width: 950px) {
    display: grid;
    grid-template-columns: repeat(1, 2fr);
    max-width: 450px;
    width: 100%;
    margin: 0 auto;
  }
`;

interface HeaderProps {
  changeTitle: boolean;
}

export const Content = styled.div<HeaderProps>`
  flex: 2;
  margin-right: 45px;

  @media (max-width: 950px) {
    margin-right: 0;
  }

  header {
    display: flex;
    justify-content: flex-end;

    @media (max-width: 950px) {
      justify-content: center;
      margin-bottom: 25px;
    }

    h1 {
      font-size: 27px;
      max-width: 200px;
      text-align: left;
      width: 100%;
      color: black;
    }

    button {
      border: none;
      outline: none;
      cursor: pointer;
      margin-left: 30px;
      background: black;
      border-radius: 5px;
      width: 50px;
      height: 40px;
      padding: 4px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease;

      svg {
        font-size: 20px;
        color: black;
        margin: 0 auto;
      }
    }
  }
`;

export const FieldsContainer = styled.div`
  margin-top: 20px;

  div {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;

    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      input {
        text-align: center;
        font-size: 17px;
      }
    }

    &:nth-child(5),
    &:nth-child(6) {
      margin-top: 10px;
    }

    &:nth-child(7) {
      label {
        margin-top: 0;
      }
    }

    @media (max-width: 950px) {
      flex-direction: column;

      input,
      textarea {
        margin-top: 5px;
        max-width: 500px !important;
      }

      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        input {
          margin-top: 0;
        }
      }
    }

    input,
    textarea {
      background: #c4c4c4;
      border: none;
      border-radius: 5px;
      padding: 12px 18px;
      color: black;
      outline: none;
      font-size: 14px;
      font-weight: bold;
      width: 100%;
      max-width: 280px;
    }

    textarea {
      resize: none;
      min-height: 180px;
      font-weight: normal;
    }

    label {
      color: black;
      display: block;
      margin-right: 15px;
      margin-top: 5px;
      font-weight: bold;
      font-size: 19px;
    }
  }
`;

export const Calendar = styled.aside`
  max-width: 40rem;
  width: 100%;

  @media (max-width: 950px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

export const Hours = styled.div`
  max-width: 400px;
  width: 100%;
  margin-top: 30px;

  @media (max-width: 950px) {
    max-width: 500px;
  }

  h4 {
    text-align: left;
    font-size: 25px;
    color: #740000;
    font-weight: normal;
  }

  table {
    display: grid;
    grid-template-columns: repeat(auto-fit, 6.5rem);
    grid-gap: 1rem;
  }
`;

export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
`;
export const TagsContainer = styled.div`
  display: flex;
  gap: 1rem;
  width: 50%;
  max-width: 280px;
  margin-left: 17.5rem;

  height: 16rem;

  .right-column {
    margin: 0 auto;
    height: 100%;
    flex-direction: column;
    gap: 1rem;

    input {
      height: 4.5rem;
    }
    div {
      justify-content: space-between;
    }
  }
  .left-column {
    margin: 0 auto;
    height: 100%;
    flex-direction: column;
    gap: 1rem;
    .dropdown {
      height: 4.5rem;
    }
  }
`;

interface TagProps {
  click: boolean;
}
export const TagContainer = styled.button<TagProps>`
  display: flex;
  max-width: 6.5rem;
  width: 100%;
  border: none;
  background: ${props => (props.click ? '#2e2b2b' : '#c4c4c4')};
  border-radius: 5px;
  text-align: center;
  height: 4.5rem;
  outline: none;
  margin-top: -2rem;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.2s ease;

  label {
    cursor: pointer;
    height: 100%;
    width: 100%;
    margin: 0.8rem auto !important;
    color: ${props => (props.click ? 'white' : 'black')} !important;
  }

  @media (max-width: 450px) {
    font-size: 15px;
    max-width: 55px;
    padding: 10px;
  }
`;

export const ContentFooter = styled.div`
  section {
    margin-top: 15px;
    display: flex;
    gap: 1rem;

    button {
      color: white;
      border: none;
      background: green;
      border-radius: 5px;
      text-align: center;
      outline: none;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
      cursor: pointer;
      width: 100%;
      font-size: 16px;
      font-weight: bold;
      padding: 10px 0;

      &:nth-child(2) {
        background: red;
      }
    }
  }
`;
