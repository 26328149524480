import { IUser } from './User';

/* eslint-disable no-shadow */

export enum LocationEnum {
  BIGORRILHO = 'Bigorrilho',
  CENTRO = 'Centro',
}

export enum AppointmentStatusEnum {
  Pending = 'Pendente',
  Done = 'Realizado',
  Canceled = 'Cancelado',
}

export enum ProfileRoleEnum {
  ADMIN = 'Admin',
  BIGORRILHO = 'Bigorrilho',
  CENTRO = 'Centro',
}

export enum PaymentTypeEnum {
  Billet = 'Boleto',
  CreditCard = 'Cartão de Crédito',
}

export enum EmissionTypeEnum {
  Online = 'A distância',
  Offline = 'Presencial',
}
export enum DocumentTypeEnum {
  CPF = 'CPF',
  CNPJ = 'CNPJ',
}
export enum CertificateEnum {
  A1 = 'A1',
  A3 = 'A3',
}
export interface IAppointmentDetails {
  id?: string;
  name: string;
  phone: string;
  email: string;
  emission_type: EmissionTypeEnum;
  payment_type: PaymentTypeEnum;
  document_type: DocumentTypeEnum;
  location: LocationEnum;
  certificate_type: CertificateEnum;
  observations: string | null;
  value?: number | null | undefined;
  created_at?: Date;
  updated_at?: Date;
}

export interface IAppointment {
  id: string;
  date: Date;
  status: AppointmentStatusEnum;
  appointment_details: IAppointmentDetails;
}

export interface IMonthDayStatus {
  month_day: number;
  available: Date[];
}

export const emptyAppointment: IAppointment = {
  id: '',
  // user: {
  //   name: '',
  //   email: '',
  //   phone_number: '',
  // } as IUser,
  date: new Date(),
  status: AppointmentStatusEnum.Done,
  appointment_details: {
    location: LocationEnum.BIGORRILHO,
    name: '',
    phone: '',
    email: '',
    document_type: DocumentTypeEnum.CNPJ,
    certificate_type: CertificateEnum.A1,
    emission_type: EmissionTypeEnum.Offline,
    payment_type: PaymentTypeEnum.Billet,
    observations: null,
  },
};
