import styled from 'styled-components';
import AuthImage from '../../../images/auth/auth01.png';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100vw;
`;

export const Background = styled.div`
  flex: 1;
  background: url(${AuthImage}), no-repeat center;
  opacity: 0.2;
  background-size: cover;
`;

export const WrapperForgotPassword = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    z-index: 1;
    max-width: 40rem;
    position: sticky;
    width: 100%;
    margin-bottom: -7rem;
  }

  form {
    margin: 8rem 0;
    text-align: center;
    width: 100%;
    max-width: 34rem;

    @media (max-width: 400px) {
      padding: 0 1rem;
    }

    button {
      background-color: #750000;
    }

    h1 {
      margin-bottom: 1.4rem;
      font-size: 2.6rem;
    }

    a {
      color: #750000;
      display: block;
      margin-top: 2.4rem;
      text-decoration: none;
      transition: color 0.3s;
    }

    p {
      //margin-top: -1.5rem;
      font-size: 1.2rem;
      margin-bottom: 2.4rem;
    }
  }

  > a {
    color: #740000;
    display: block;
    margin-top: 2.4rem;
    text-decoration: none;
    transition: color 0.3s;
    display: flex;
    align-items: center;
    font-size: 1.6rem;

    svg {
      margin-right: 1.6rem;
    }
  }
`;
