import styled from 'styled-components';
import colors from '../../styles/colors';

export const NotFoundContainer = styled.div`
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-width: 100vw;
  min-height: 100vh;

  h2 {
    font-weight: 800;
    font-size: 18px;
    line-height: 19px;
    text-align: center;
    color: ${colors.secondary};
    margin-top: 28px;
  }

  a {
    margin-top: 20px;
    text-decoration: none;
    font-size: 18px;
    color: #740000, 90%;
    transition: color 0.3s;

    &:hover {
      color: #740000;
    }
  }
`;
