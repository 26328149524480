import { AxiosResponse } from 'axios';
import { format, parseISO } from 'date-fns';
import { api, RawHTTPResponse, getAuthHeader } from '../api';
import useApiCall from '../../../hooks/apiCall';
import { IAppointment, IMonthDayStatus } from '../../../models/Appointment';

interface GetMonthAppointmentsStatus {
  Request: {
    date: Date;
  };
  Response: IMonthDayStatus[];
}

export const useGetMonthAppointmentsStatus = () =>
  useApiCall<
    GetMonthAppointmentsStatus['Request'],
    GetMonthAppointmentsStatus['Response']
  >({
    request: async ({ date }, token) => {
      const {
        data,
      }: RawHTTPResponse<
        {
          day: number;
          available: string[];
        }[]
      > = await api.get(
        `/schedules/month-availability?month=${
          date.getMonth() + 1
        }&year=${date.getFullYear()}`,
        getAuthHeader(token),
      );
      return data.map(({ day, available }) => ({
        month_day: day,
        available: available.map(a => parseISO(a)),
      }));
    },
    errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível buscar a disponibilidade desse mês',
    },
  });

interface GetDayAppointments {
  Request: {
    date: Date;
    filter_name?: string;
  };
  Response: IAppointment[];
}

export const useGetDayAppointments = () =>
  useApiCall<GetDayAppointments['Request'], GetDayAppointments['Response']>({
    request: async ({ date, filter_name }, token) => {
      const {
        data,
      }: RawHTTPResponse<GetDayAppointments['Response']> = await api.get(
        ''.concat(
          `/appointments?date=${format(date, 'yyyy-MM-dd')}`,
          filter_name ? `&filter_name=${filter_name}` : '',
        ),
        getAuthHeader(token),
      );
      return data.map(apt => ({
        ...apt,
        date: parseISO((apt.date as unknown) as string),
      }));
    },
    errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível buscar os agendamentos',
    },
  });

interface UpdateAppointmentStatus {
  Request: {
    id: string;
    status: IAppointment['status'];
  };
  Response: IAppointment;
}

export const useUpdateAppointmentStatus = () =>
  useApiCall<
    UpdateAppointmentStatus['Request'],
    UpdateAppointmentStatus['Response']
  >({
    request: async ({ id, status }, token) => {
      const {
        data,
      }: RawHTTPResponse<UpdateAppointmentStatus['Response']> = await api.put(
        '/appointments/status',
        {
          id,
          status,
        },
        getAuthHeader(token),
      );
      return data;
    },
    errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível atualizar o status',
    },
  });

interface UpdateAppointment {
  Request: {
    appointment: Pick<IAppointment, 'id' | 'date' | 'appointment_details'>;
  };
  Response: IAppointment;
}

export const useUpdateAppointment = () =>
  useApiCall<UpdateAppointment['Request'], UpdateAppointment['Response']>({
    request: async ({ appointment }, token) => {
      const {
        data,
      }: RawHTTPResponse<UpdateAppointment['Response']> = await api.put(
        '/appointments',
        appointment,
        getAuthHeader(token),
      );
      return data;
    },
    errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível atualizar o agendamento',
    },
  });

interface CreateAppointment {
  Request: {
    appointment: Pick<IAppointment, 'date' | 'appointment_details'>;
  };
  Response: IAppointment;
}

export const useCreateAppointment = () =>
  useApiCall<CreateAppointment['Request'], CreateAppointment['Response']>({
    request: async ({ appointment }, token) => {
      const {
        data,
      }: RawHTTPResponse<CreateAppointment['Response']> = await api.post(
        '/appointments',
        appointment,
        getAuthHeader(token),
      );
      return data;
    },
    errorMessage: {
      title: 'Erro na requisição',
      description: 'Não foi possível criar o agendamento',
    },
  });
