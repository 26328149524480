import { AxiosResponse } from 'axios';
import { ILocalStorageUser, IUser } from '../../../models/User';
import useApiCall from '../../../hooks/apiCall';
import { api, AxiosError } from '../api';

interface Login {
  Request: {
    email: string;
    password: string;
  };
  Response: {
    user: IUser;
    token: string;
  };
}

export const useLogin = () =>
  useApiCall<Login['Request'], Login['Response']>({
    request: async ({ email = 'teste', password }) => {
      const res: AxiosResponse<Login['Response']> = await api.post(
        '/sessions',
        {
          email,
          password,
        },
      );
      return res.data;
    },
    errorMessage: 'Não foi possível realizar a requisição',
    getDynamicErrorMessage: (error: AxiosError) => {
      if (error.isAxiosError) {
        const errorMessage: string = error.response?.data?.error;
        if (errorMessage === 'Email/Senha inválidos.') {
          const toastMessage = 'Email/Senha inválidos.';
          return toastMessage;
        }
      }

      return '';
    },
  });

export interface SignUp {
  Request: {
    name: string;
    email: string;
    password: string;
    password_confirmation: string;
    phone_number: string;
  };
  Response: {
    user: ILocalStorageUser;
    token: string;
  };
}

export const useSignUp = () =>
  useApiCall<SignUp['Request'], SignUp['Response']>({
    request: async ({
      name,
      email,
      password,
      password_confirmation,
      phone_number,
    }) => {
      const res: AxiosResponse<SignUp['Response']> = await api.post(
        '/referrals',
        {
          name,
          email,
          password,
          password_confirmation,
          phone_number,
        },
      );
      return res.data;
    },
    errorMessage: {
      title: 'Erro na requisição',
      description:
        'Não foi possível alterar realizar o cadastro, tente mais tarde',
    },
  });
interface ForgotPassword {
  Request: {
    email: string;
  };

  Response: {
    error?: string;
  };
}

export const useForgotPassword = () =>
  useApiCall<ForgotPassword['Request'], ForgotPassword['Response']>({
    request: async ({ email }) => {
      const res: AxiosResponse<ForgotPassword['Response']> = await api.post(
        '/password/forgot',
        {
          email,
        },
      );
      return res.data;
    },
    errorMessage: 'Não foi possível realizar a requisição',
    getDynamicErrorMessage: (error: AxiosError) => {
      if (error.isAxiosError) {
        const errorMessage: string = error.response?.data?.error;
        if (errorMessage === 'User does not exist.') {
          const toastMessage = 'Esse usuário não existe';
          return toastMessage;
        }
      }

      return '';
    },
  });
