import axios from 'axios';

export type { AxiosResponse as RawHTTPResponse, AxiosError } from 'axios';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export interface PaginatedResponse<T> {
  data: T[];
  cursor: {
    afterCursor: string | null;
    beforeCursor: string | null;
  };
}

export const getAuthHeader = (token: string | undefined) => ({
  headers: {
    Authorization: `Bearer ${token || ''}`,
  },
});
