import styled from 'styled-components';
import colors from '../../styles/colors';

export const StyledSelect = styled.select`
  /* styling */
  display: inline-block;
  width: 100%;
  max-width: 280px;

  background-color: #c4c4c4;
  border: none;
  border-radius: 5px;
  padding: 12px 18px;
  color: black;
  outline: none;
  font-size: 14px;
  font-weight: bold;

  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  /* arrows */

  background-image: linear-gradient(
      45deg,
      transparent 51%,
      ${colors.lightGrey} 50%
    ),
    linear-gradient(135deg, ${colors.lightGrey} 50%, transparent 51%),
    linear-gradient(to right, transparent, transparent);
  background-position: calc(100% - 12px) calc(1em + 6px),
    calc(100% - 6px) calc(1em + 6px), 100% 0;
  background-size: 6px 6px, 6px 6px, 3.5em 3.5em;
  background-repeat: no-repeat;

  @media (max-width: 950px) {
    margin-top: 5px;
    max-width: 500px !important;
  }

  /* &:active {
    background-image: linear-gradient(
        135deg,
        transparent 51%,
        ${colors.lightGrey} 50%
      ),
      linear-gradient(45deg, ${colors.lightGrey} 50%, transparent 51%),
      linear-gradient(to right, ${colors.secondary}, ${colors.secondary});
    border-color: grey;
    outline: 0;
  } */

  &:focus {
    border: 1px solid ${colors.secondary};
  }

  &:disabled {
    opacity: 50%;
  }

  option {
    background-color: ${colors.lightGrey};
  }
`;
