import React from 'react';

import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { LoadingBarProvider } from './loadingBar';
import { BackgroundFixedProvider } from './isBackgroundFixed';
import { FullscreenLoadingProvider } from './fullscreenLoading';

const AppProvider: React.FC = ({ children }) => (
  <FullscreenLoadingProvider>
    <ToastProvider>
      <LoadingBarProvider>
        <AuthProvider>
          <BackgroundFixedProvider>{children}</BackgroundFixedProvider>
        </AuthProvider>
      </LoadingBarProvider>
    </ToastProvider>
  </FullscreenLoadingProvider>
);

export default AppProvider;
