import React, { useState } from 'react';
import Navbar from './Navbar';
import SideBar from './SideBar';

const Header: React.FC = () => {
  const [openSideBar, setOpenSideBar] = useState<boolean>(false);

  return (
    <>
      <Navbar setOpenSideBar={setOpenSideBar} openSideBar={openSideBar} />
      <SideBar open={openSideBar} setOpenSideBar={setOpenSideBar} />
    </>
  );
};

export default Header;
