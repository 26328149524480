import React, { useState, useRef } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { Container, Header, ContentContainer } from './styles';

interface Props {
  name?: string;
  children: JSX.Element;
}

const Appointments: React.FC<Props> = ({ name = '', children }: Props) => {
  const [Active, setActive] = useState<boolean>(false);
  const [MaxHeight, setMaxHeight] = useState<string>('0px');

  const content = useRef<HTMLHeadingElement>(null);

  function setHeight(): void {
    if (content.current) {
      setActive(!Active);
      if (Active) {
        setMaxHeight('0px');
      } else {
        setMaxHeight(`${content.current.scrollHeight}px`);
      }
    }
  }
  return (
    <Container>
      <Header onClick={setHeight}>
        <h4>{name}</h4>
        <IoIosArrowDown />
      </Header>
      <ContentContainer maxHeight={MaxHeight} ref={content}>
        {children}
      </ContentContainer>
    </Container>
  );
};

export default Appointments;
