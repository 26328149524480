import React, { useState } from 'react';

import { RouteComponentProps, Link, withRouter } from 'react-router-dom';

import * as yup from 'yup';

import { FiMail, FiArrowLeft } from 'react-icons/fi';
import {
  Container,
  Content,
  WrapperForgotPassword,
  Background,
} from './styles';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Logo from '../../../images/logo-ysa.jpg';
import { useToast } from '../../../hooks/toast';
import { useForgotPassword } from '../../../services/api/sessions';
import { emailValidator } from '../../../utils/yupValidators';

type Props = RouteComponentProps;

const ForgotPassword: React.FC = () => {
  const forgotPassword = useForgotPassword();
  const { addToast } = useToast();
  const [email, setEmail] = useState('');
  const handleSubmit = async () => {
    try {
      await emailValidator.validate(email);

      await forgotPassword({ email });

      addToast({
        type: 'success',
        title: '',
        description: 'Você recebeu um email com o link de recuperação de senha',
      });
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        addToast({
          type: 'error',
          title: '',
          description: `${error.errors[0]}`,
        });
      }
    }
  };

  return (
    <Container>
      <Content>
        <WrapperForgotPassword>
          {/* <img src={Logo} alt="Logo - ysa" /> */}

          <form>
            <h1> Esqueceu a senha? </h1>
            <p>
              Preencha abaixo seu endereço de email para receber as instruções
              necessárias para criar uma nova senha.
            </p>

            <Input
              name="email"
              icon={FiMail}
              placeholder="E-mail"
              type="email"
              onChange={e => setEmail(e.target.value)}
            />

            <Button textColor="white" type="button" onClick={handleSubmit}>
              Recuperar senha
            </Button>
          </form>

          <Link to="/">
            <FiArrowLeft />
            Voltar para login
          </Link>
        </WrapperForgotPassword>
      </Content>
    </Container>
  );
};

export default ForgotPassword;
