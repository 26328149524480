import React, { SelectHTMLAttributes } from 'react';
import { StyledSelect } from './styles';

const DropdownList: React.FC<SelectHTMLAttributes<HTMLSelectElement>> = ({
  children,
  ...attrs
}) => {
  return <StyledSelect {...attrs}>{children}</StyledSelect>;
};

export default DropdownList;
