/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from 'react';
import { HiOutlinePencil } from 'react-icons/hi';
import { useHistory, useLocation } from 'react-router';
import {
  addDays,
  lastDayOfMonth,
  setHours,
  setMinutes,
  setSeconds,
  differenceInCalendarMonths,
  isSunday,
  isThisMonth,
  setDate,
  isSameDay,
  isSameMinute,
  format,
} from 'date-fns';
import * as yup from 'yup';
import Skeleton from 'react-loading-skeleton';
import {
  Row,
  Calendar,
  Content,
  Container,
  FieldsContainer,
  Hours,
  ContentFooter,
  TagsContainer,
  TagContainer,
} from './styles';
import HourItem from '../../../../components/HourItem';
import AppointmentDayPicker from '../../../../components/AppointmentDayPicker';
import {
  IAppointment,
  IMonthDayStatus,
  emptyAppointment,
  PaymentTypeEnum,
  EmissionTypeEnum,
  LocationEnum,
  IAppointmentDetails,
  DocumentTypeEnum,
  CertificateEnum,
} from '../../../../models/Appointment';
import { useGetMonthAppointmentsStatus } from '../../../../services/api';
import DropdownList from '../../../../components/DropdownList';
import { IUser } from '../../../../models/User';
import {
  nameValidator,
  emailValidator,
  phoneNumberValidator,
} from '../../../../utils/yupValidators';
import { phoneNumberMask } from '../../../../utils/masks';
import { useToast } from '../../../../hooks/toast';
import {
  useCreateAppointment,
  useUpdateAppointment,
} from '../../../../services/api/appointments';
import { ContentRowButton } from '../../../../components/HourItem/styles';
import {
  getCurrencyMask,
  getRawCurrency,
} from '../../../../utils/currencyFormatter';

const setDayMoment = (hours: number, minutes = 0, day: Date = new Date()) => {
  return setSeconds(setMinutes(setHours(day, hours), minutes), 0);
};

interface TagProp {
  isSelected: boolean;
  text: string;
  onClick: (nextHour: string) => void;
}

const TagItem: React.FC<TagProp> = ({ isSelected, onClick, text }: TagProp) => {
  return (
    <TagContainer
      click={isSelected}
      type="button"
      onClick={() => onClick(text)}
    >
      <label>{text}</label>
    </TagContainer>
  );
};

const Schedule: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const { addToast } = useToast();

  const updateAppointment = useUpdateAppointment();
  const createAppointment = useCreateAppointment();

  const getMonthAppointmentsStatus = useGetMonthAppointmentsStatus();

  const [changeTitle, setChangeTitle] = useState<boolean>(true);
  const [appointment, setAppointment] = useState<IAppointment>(({
    ...emptyAppointment,
  } as unknown) as IAppointment);
  const [selectedDay, setSelectedDay] = useState<Date>(addDays(new Date(), 5));
  const [currentAppointmentDate, setCurrentAppointmentDate] = useState<
    Date | undefined
  >(undefined);
  const [selectedMonth, setSelectedMonth] = useState<Date>(
    addDays(new Date(), 0),
  );
  const [monthStatus, setMonthStatus] = useState<IMonthDayStatus[]>([]);
  const [isMonthLoading, setIsMonthLoading] = useState<boolean>(false);

  const [cpfTag, setCPFtag] = useState(
    appointment.appointment_details.document_type === DocumentTypeEnum.CPF,
  );
  const [cnpjTag, setCNPJtag] = useState(
    appointment.appointment_details.document_type === DocumentTypeEnum.CNPJ,
  );
  const [a1Tag, setA1tag] = useState(false);
  const [a3Tag, setA3tag] = useState(false);
  const [price, setPrice] = useState(0);

  useEffect(() => {
    if (
      appointment.appointment_details.certificate_type === CertificateEnum.A1
    ) {
      setA1tag(true);
      setA3tag(false);
    } else {
      setA1tag(false);
      setA3tag(true);
    }

    if (
      appointment.appointment_details.document_type === DocumentTypeEnum.CNPJ
    ) {
      setCNPJtag(true);
      setCPFtag(false);
    } else {
      setCNPJtag(false);
      setCPFtag(true);
    }
  }, [
    appointment.appointment_details.certificate_type,
    appointment.appointment_details.document_type,
  ]);

  useEffect(() => {
    const {
      appointment: { id, status, date, appointment_details },
    } = (location.state || { appointment: {} }) as {
      appointment: IAppointment;
    };

    if (id && status && date && appointment_details) {
      setAppointment({ id, status, date, appointment_details });
      setCurrentAppointmentDate(date);
    } else {
      setChangeTitle(false);
    }
  }, []);

  useEffect(() => {
    (async () => {
      setIsMonthLoading(true);
      try {
        const month = await getMonthAppointmentsStatus({
          date: selectedMonth,
        });
        setMonthStatus(month);
        setSelectedDay(
          isThisMonth(selectedMonth)
            ? appointment.date
            : setDate(selectedMonth, 1),
        );
      } catch (error) {
      } finally {
        setIsMonthLoading(false);
      }
    })();
  }, [selectedMonth]);

  const updateAptData = (
    data: Partial<IAppointment> | Partial<IAppointmentDetails>,
    toData: undefined | 'details' = undefined,
  ) => {
    switch (toData) {
      case 'details':
        setAppointment(apt => ({
          ...apt,
          appointment_details: { ...apt.appointment_details, ...data },
        }));
        return;
      default:
        setAppointment(apt => ({ ...apt, ...data }));
    }
  };

  const handleSubmit = async () => {
    try {
      const schema = yup.object().shape({
        appointment_details: yup.object().shape({
          name: nameValidator,
          phone: phoneNumberValidator,
          email: emailValidator,
          value: yup.number(),
          payment_type: yup
            .string()
            .typeError('Método de pagamento incorreta')
            .required('Método de pagamento é obrigatória')
            .equals(
              [...Object.values(PaymentTypeEnum)],
              'Método de pagamento inválida',
            ),
          location: yup
            .string()
            .typeError('Local incorreto')
            .required('Local é obrigatório')
            .equals([...Object.values(LocationEnum)], 'Local inválido'),
          emission_type: yup
            .string()
            .typeError('Tipo de emissão incorreto')
            .required('Tipo de emissão é obrigatório')
            .equals(
              [...Object.values(EmissionTypeEnum)],
              'Tipo de emissão inválido',
            ),
          document_type: yup
            .string()
            .typeError('Documento incorreto')
            .required('Um documento é obrigatório')
            .equals([...Object.values(DocumentTypeEnum)], 'Documento inválido'),
          certificate_type: yup
            .string()
            .typeError('Certificação incorreta')
            .required('Uma certificação é obrigatória')
            .equals(
              [...Object.values(CertificateEnum)],
              'Certificação inválida',
            ),
        }),
        date: yup
          .date()
          .typeError('Data incorreta')
          .required('Data é obrigatória')
          .min(new Date(), 'Data inválida'),
      });

      await schema.validate(
        {
          ...appointment,
          date: currentAppointmentDate,
        },
        { abortEarly: false },
      );

      try {
        if (currentAppointmentDate) {
          const { id, appointment_details } = appointment;
          if (id) {
            delete appointment_details.id;
            delete appointment_details.created_at;
            delete appointment_details.updated_at;

            await updateAppointment({
              appointment: {
                id,
                date: currentAppointmentDate,
                appointment_details,
              },
            });
          } else {
            await createAppointment({
              appointment: {
                date: currentAppointmentDate,
                appointment_details: {
                  ...appointment_details,
                },
              },
            });
          }

          addToast({
            type: 'success',
            title: 'Tudo ok!',
            description: 'Agendamento realizado',
          });

          history.push('/appointments');
        }
      } catch (error) {}
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        addToast({
          type: 'error',
          title: 'Dado inválido',
          description: `${error.errors[0]}`,
        });
      }
    }
  };

  const fullDays = useMemo(
    () =>
      monthStatus
        .filter(d => d.available.length === 0)
        .map(d => {
          const dd = new Date(selectedMonth.getTime());
          dd.setDate(d.month_day);
          return dd;
        }),
    [monthStatus],
  );

  return (
    <Container>
      <Row>
        <Content changeTitle={changeTitle}>
          <header>
            {changeTitle ? <h1>Editar</h1> : <h1>Agendamento</h1>}
            {/* <button type="button" onClick={() => setChangeTitle(!changeTitle)}>
              <HiOutlinePencil />
            </button> */}
          </header>
          <FieldsContainer>
            <div>
              <label htmlFor="">Nome</label>
              <input
                type="text"
                value={appointment.appointment_details.name}
                onChange={e =>
                  updateAptData({ name: e.target.value }, 'details')
                }
              />
            </div>
            <div>
              <label htmlFor="">Telefone</label>
              <input
                type="text"
                value={appointment.appointment_details.phone}
                onChange={e =>
                  updateAptData(
                    { phone: phoneNumberMask(e.target.value) },
                    'details',
                  )
                }
              />
            </div>
            <div>
              <label htmlFor="">Email</label>
              <input
                type="email"
                value={appointment.appointment_details.email}
                onChange={e =>
                  updateAptData({ email: e.target.value }, 'details')
                }
              />
            </div>
            <TagsContainer className="tags-container">
              <div className="left-column">
                <DropdownList
                  className="dropdown"
                  defaultValue="NULL"
                  value={
                    [...Object.values(PaymentTypeEnum)].includes(
                      appointment.appointment_details.payment_type,
                    )
                      ? appointment.appointment_details.payment_type
                      : 'NULL'
                  }
                  onChange={e =>
                    updateAptData(
                      {
                        payment_type: e.target.value as PaymentTypeEnum,
                      },
                      'details',
                    )
                  }
                >
                  <option value="NULL" disabled>
                    Selecione
                  </option>
                  {Object.values(PaymentTypeEnum).map(v => (
                    <option key={v} value={v}>
                      {v}
                    </option>
                  ))}
                </DropdownList>
                <DropdownList
                  className="dropdown"
                  defaultValue="NULL"
                  value={
                    [...Object.values(EmissionTypeEnum)].includes(
                      appointment.appointment_details.emission_type,
                    )
                      ? appointment.appointment_details.emission_type
                      : 'NULL'
                  }
                  onChange={e =>
                    updateAptData(
                      {
                        emission_type: e.target.value as EmissionTypeEnum,
                      },
                      'details',
                    )
                  }
                >
                  <option value="NULL" disabled>
                    Selecione
                  </option>
                  {Object.values(EmissionTypeEnum).map(v => (
                    <option key={v} value={v}>
                      {v}
                    </option>
                  ))}
                </DropdownList>

                <DropdownList
                  className="dropdown"
                  defaultValue="NULL"
                  value={
                    [...Object.values(LocationEnum)].includes(
                      appointment.appointment_details.location,
                    )
                      ? appointment.appointment_details.location
                      : 'NULL'
                  }
                  onChange={e =>
                    updateAptData(
                      {
                        location: e.target.value as LocationEnum,
                      },
                      'details',
                    )
                  }
                >
                  <option value="NULL" disabled>
                    Selecione
                  </option>
                  {Object.values(LocationEnum).map(v => (
                    <option key={v} value={v}>
                      {v}
                    </option>
                  ))}
                </DropdownList>
              </div>
              <div className="right-column">
                <input
                  value={getCurrencyMask(
                    appointment.appointment_details.value || 0,
                  )}
                  onChange={e => {
                    updateAptData(
                      {
                        value: getRawCurrency(e.target.value),
                      },
                      'details',
                    );
                  }}
                />
                <div>
                  <TagItem
                    isSelected={cpfTag}
                    text="CPF"
                    onClick={() => {
                      updateAptData(
                        {
                          document_type: DocumentTypeEnum.CPF,
                        },
                        'details',
                      );
                    }}
                  />
                  <TagItem
                    isSelected={cnpjTag}
                    text="CNPJ"
                    onClick={() => {
                      updateAptData(
                        {
                          document_type: DocumentTypeEnum.CNPJ,
                        },
                        'details',
                      );
                    }}
                  />
                </div>
                <div>
                  <TagItem
                    isSelected={a1Tag}
                    text="A1"
                    onClick={() => {
                      updateAptData(
                        {
                          certificate_type: CertificateEnum.A1,
                        },
                        'details',
                      );
                      setA1tag(!a1Tag);
                      setA3tag(false);
                    }}
                  />
                  <TagItem
                    isSelected={a3Tag}
                    text="A3"
                    onClick={() => {
                      updateAptData(
                        {
                          certificate_type: CertificateEnum.A3,
                        },
                        'details',
                      );
                      setA3tag(!a3Tag);
                      setA1tag(false);
                    }}
                  />
                </div>
              </div>
            </TagsContainer>

            <div>
              <label htmlFor="">Observações</label>
              <textarea
                value={appointment.appointment_details.observations || ''}
                onChange={e =>
                  updateAptData({ observations: e.target.value }, 'details')
                }
              />
            </div>
          </FieldsContainer>
        </Content>
        <Calendar>
          {isMonthLoading ? (
            <Skeleton duration={2} height={360} />
          ) : (
            <AppointmentDayPicker
              fromMonth={new Date()}
              onChangeDay={(date: React.SetStateAction<Date>) => {
                setSelectedDay(date);
                // eslint-disable-next-line no-debugger
                // debugger;
              }}
              onChangeMonth={(date: React.SetStateAction<Date>) => {
                setSelectedMonth(date);
              }}
              currentDay={selectedDay}
              currentMonth={selectedMonth}
              unavailableDays={fullDays}
            />
          )}
          <Hours>
            {differenceInCalendarMonths(selectedMonth, selectedDay) === 0 ? (
              !isSunday(selectedDay) ? (
                monthStatus.filter(
                  m => m.month_day === selectedDay.getDate(),
                )[0]?.available.length > 0 ? (
                  <>
                    {currentAppointmentDate && (
                      <p style={{ fontSize: '1.5rem' }}>
                        Agendado para{' '}
                        {format(currentAppointmentDate, 'dd/MM/yyyy HH:mm')}
                      </p>
                    )}
                    <h4>Escolher horário</h4>
                    <table>
                      {[
                        ...monthStatus.filter(
                          m => m.month_day === selectedDay.getDate(),
                        )[0]?.available,
                        ...(appointment.date &&
                        isSameDay(selectedDay, appointment.date)
                          ? [appointment.date]
                          : []),
                      ]
                        .sort((a, b) => Number(a) - Number(b))
                        .map(hour => (
                          <HourItem
                            isSelected={
                              (currentAppointmentDate &&
                                isSameMinute(hour, currentAppointmentDate)) ||
                              false
                            }
                            hour={hour}
                            onClick={setCurrentAppointmentDate}
                          />
                        ))}
                    </table>
                    <ContentFooter>
                      <section>
                        <button type="button" onClick={handleSubmit}>
                          Confirmar
                        </button>
                        <button type="button" onClick={history.goBack}>
                          Cancelar
                        </button>
                      </section>
                    </ContentFooter>
                  </>
                ) : (
                  <h4>Sem horários disponíveis para essa data</h4>
                )
              ) : (
                <h4>Escolha um dia válido</h4>
              )
            ) : (
              <h4>Escolha uma data</h4>
            )}
          </Hours>
        </Calendar>
      </Row>
    </Container>
  );
};

export default Schedule;
