import React, { useEffect, useState } from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { FiPower } from 'react-icons/fi';
import { VscThreeBars } from 'react-icons/vsc';
import {
  Container,
  Content,
  Profile,
  LinkContainer,
  SideBarButton,
  LogoutButton,
} from './styles';
import profile from '../../../images/header/profile.png';
import { useAuth } from '../../../hooks/auth';
import { ProfileRoleEnum } from '../../../models/Appointment';

type Props = RouteComponentProps & {
  setOpenSideBar: (open: boolean) => void;
  openSideBar: boolean;
};

const Navbar: React.FC<Props> = ({
  location: { pathname },
  setOpenSideBar,
  openSideBar,
}: Props) => {
  const { signOut, user } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (user.profile_role && user.profile_role === ProfileRoleEnum.ADMIN) {
      setIsAdmin(true);
    }
  }, [user]);

  return (
    <Container>
      <Content>
        {/* <img src={logoImg} alt="Logo GoBarber" /> */}
        <Profile>
          <img src={profile} alt="Alê Osorio" />
          <div>
            <span>Bem-vindo,</span>
            {/* <NavLink to="/profile"> */}
            <strong>{user?.name || 'Sem usuário logado'}</strong>
            {/* </NavLink> */}
          </div>
        </Profile>
        <LinkContainer>
          <NavLink
            to="/appointments"
            className={pathname === '/appointments' ? 'white' : ''}
          >
            Agendamento
          </NavLink>
          {isAdmin ? (
            <NavLink
              to="/users"
              className={pathname === '/users' ? 'white' : ''}
            >
              Usuários
            </NavLink>
          ) : (
            <></>
          )}
        </LinkContainer>
        <LogoutButton type="button" onClick={signOut}>
          <FiPower size={35} />
        </LogoutButton>
        <SideBarButton
          type="button"
          onClick={() => setOpenSideBar(!openSideBar)}
        >
          <VscThreeBars size={35} />
        </SideBarButton>
      </Content>
    </Container>
  );
};

export default withRouter(Navbar);
