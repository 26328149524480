import React, { ReactNode, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import * as Yup from 'yup';
import Avatar from '../../../../components/Avatar';
import DropdownList from '../../../../components/DropdownList';
import { useToast } from '../../../../hooks/toast';
import { ProfileRoleEnum } from '../../../../models/Appointment';
import { IUser } from '../../../../models/User';
import { useCreateUser, useUpdateUser } from '../../../../services/api/users';

import { Container, InputPhoto } from './styles';

interface UsersProps {
  children: ReactNode;
}

const Users: React.FC = () => {
  const createUser = useCreateUser();
  const history = useHistory();
  const location = useLocation();
  const updateUser = useUpdateUser();

  const { oldUser } = (location.state || { oldUser: {} }) as { oldUser: IUser };
  const { addToast } = useToast();
  const [id, setId] = useState(oldUser.id);
  console.log('id', id);
  const [name, setName] = useState(oldUser.name || '');
  const [email, setEmail] = useState(oldUser.email || '');
  const [password, setPassword] = useState<string>();
  const [access, setAccess] = useState<ProfileRoleEnum>(
    oldUser.profile_role || ProfileRoleEnum.ADMIN,
  );
  const [imageFile, setImgFile] = useState<File>(new File([], ''));
  const [imgSource, setImgSource] = useState<string>(oldUser.avatar || '');

  function handleInputFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = e => {
        const { result } = e.target!;
        setImgSource(result as string);
      };
      reader.readAsDataURL(event.target.files[0]);
      setImgFile(event.target.files[0]);
    }
  }
  useEffect(() => {
    setName(oldUser.name);
    setAccess(oldUser.profile_role);
    setImgSource(oldUser.avatar || '');
    setId(oldUser.id);
    // if (oldUser.id && oldUser.name && oldUser.profile_role && oldUser.avatar) {
    // }
    console.log(oldUser, 'OldUser');
  }, [id]);

  function handleClick() {
    const inputId = document.getElementById('files');
    inputId?.click();
  }

  const handleSubmit = async () => {
    try {
      const schema = id
        ? Yup.object().shape({
            name: Yup.string().required('O nome é obrigatório'),
            email: Yup.string().email().required('O email é obrigatório'),
            password: Yup.string().test(
              'Minimo 8',
              'A senha deve conter no mínimo oito caracteres',
              value => {
                const string = value || '';
                return string.trim().length > 0 ? string.length >= 8 : true;
              },
            ),
            profile_role: Yup.string()
              .required('O Nível de Acesso é obrigatório')
              .oneOf(
                Object.values(ProfileRoleEnum),
                'Nível de Acesso inválido',
              ),
          })
        : Yup.object().shape({
            name: Yup.string().required('O nome é obrigatório'),
            email: Yup.string().email().required('O email é obrigatório'),
            password: Yup.string()
              .required('A senha é obrigatória')
              .min(8, 'A senha deve conter no mínimo 8 caracteres'),
            profile_role: Yup.string()
              .required('O Nível de Acesso é obrigatório')
              .oneOf(
                Object.values(ProfileRoleEnum),
                'Nível de Acesso inválido',
              ),
            avatar: Yup.string().required(
              'O Avatar é obrigatório, não encontramos nenhum arquivo',
            ),
          });

      await schema.validate(
        {
          name,
          email,
          password,
          profile_role: access,
          avatar: imageFile,
        },
        { abortEarly: false },
      );
      console.log('epa nenem');

      if (id) {
        console.log('if');
        await updateUser({
          user: {
            email,
            name,
            profile_role: access,
            password,
            id,
          },
        });
      } else if (typeof password === 'string') {
        console.log('else');
        await createUser({
          user: {
            name,
            email,
            profile_role: access,
            password,
          },
          imageFile,
        });
      }

      history.push('/users');
      if (id) {
        addToast({
          title: 'Usuário atualizado com sucesso',
          type: 'success',
        });
      } else {
        addToast({
          title: 'Usuário criado com sucesso',
          type: 'success',
        });
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const firstErrorMessage = error.errors[0];
        addToast({
          type: 'error',
          title: 'Erro nos dados informados',
          description: firstErrorMessage,
        });
      } else {
        addToast({
          type: 'error',
          title: 'Erro nos dados informados',
          description: error.message,
        });
      }
    }
  };

  return (
    <Container>
      <div className="avatar-container">
        <h1>{id ? 'Editar Usuário' : 'Novo Usuário'}</h1>
        <InputPhoto imgSource={imgSource}>
          <button
            type="button"
            onClick={handleClick}
            className="background-image"
          >
            {imgSource ? (
              <div className="avatar-container">
                <img src={imgSource} alt="Trocar Foto" className="avatar" />
              </div>
            ) : (
              <label>
                Clique aqui
                <p>Para adicionar o avatar do usuário.</p>
              </label>
            )}

            <input
              type="file"
              name="files"
              id="files"
              onChange={handleInputFileChange}
              hidden
            />
          </button>
        </InputPhoto>
      </div>
      <div>
        <div>
          <label htmlFor="">Nome</label>
          <input
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="">e-mail</label>
          <input
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        {id ? (
          <div>
            <label htmlFor="">Senha</label>
            <input
              type="password"
              placeholder="**********************"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
        ) : (
          <div>
            <label htmlFor="">Senha</label>
            <input
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
        )}
        <div>
          <label htmlFor="">Nível de Acesso</label>
          <DropdownList
            className="dropdown"
            defaultValue="NULL"
            value={
              [...Object.values(ProfileRoleEnum)].includes(access)
                ? access
                : 'NULL'
            }
            onChange={e => setAccess(e.target.value as ProfileRoleEnum)}
          >
            <option value="NULL" disabled>
              Selecione
            </option>
            {Object.values(ProfileRoleEnum).map(v => (
              <option key={v} value={v}>
                {v}
              </option>
            ))}
          </DropdownList>
        </div>

        <section>
          <button
            type="button"
            className="btn btn--success"
            onClick={handleSubmit}
          >
            Concluir
          </button>
          <button
            type="button"
            className="btn btn--canceled"
            onClick={() => history.push('/users')}
          >
            Cancelar
          </button>
        </section>
      </div>
    </Container>
  );
};

export default Users;
