import React, { useState } from 'react';
import { format } from 'date-fns';
import { ContentRowButton } from './styles';

interface Props {
  isSelected: boolean;
  hour: Date;
  onClick: (nextHour: Date) => void;
}

const HourItem: React.FC<Props> = ({ isSelected, hour, onClick }: Props) => {
  return (
    <ContentRowButton
      click={isSelected}
      type="button"
      onClick={() => onClick(hour)}
    >
      <label>{format(hour, 'HH:mm')}</label>
    </ContentRowButton>
  );
};

export default HourItem;
