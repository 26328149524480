import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useLoadingBar } from './loadingBar';
import { useToast } from './toast';
import { useAuth } from './auth';

// TODO Possible Params
// TODO   onError, before, after, loadingUI = true (pessoa desabilita a loading UI se quiser);
interface APICallHookParams<T, S> {
  request: (requestData: T, token?: string) => Promise<S>;
  errorMessage?: string | { title: string; description: string };
  disableAlert?: boolean;
  getDynamicErrorMessage?: (error: any) => string;
}

type ApiCallHook = <T, S>(
  hookParams: APICallHookParams<T, S>,
) => (reqParams: T) => Promise<S>;

// This is not a hook
const useApiCall: ApiCallHook = ({
  request,
  errorMessage = 'Erro na requisição',
  disableAlert = false,
  getDynamicErrorMessage = () => '',
}) => {
  // TODO: use generic terminology, e.g. isUILoading, startLoadingUI, etc.
  const { isBarLoading, startBarLoading, completeBarLoading } = useLoadingBar();

  // TODO: enhance hook terminology (
  // TODO:    useNotifier,
  // TODO:    addToast -> notify (notify.error(''), notify.success(''),
  // TODO:     notify.info({ title: '', description: '') })));
  const { addToast } = useToast();

  const { token, signOut } = useAuth();

  const callRequest = useCallback(
    async reqParams => {
      try {
        startBarLoading();
        return await request(reqParams, token);
      } catch (error) {
        const errorTitle =
          typeof errorMessage === 'object' ? errorMessage.title : '';

        const defaultErrorDescription =
          typeof errorMessage === 'object'
            ? errorMessage.description
            : errorMessage;

        const dynamicErrorDescription = getDynamicErrorMessage(error);

        const errorDescription = dynamicErrorDescription.length
          ? dynamicErrorDescription
          : defaultErrorDescription;

        if (!disableAlert) {
          addToast({
            type: 'error',
            title: errorTitle,
            description: errorDescription,
          });
        }

        if (error && error.isAxiosError) {
          const axiosError = error as AxiosError;
          if (axiosError.response?.status === 401) {
            signOut();
          }
        }

        throw new Error(errorDescription);
      } finally {
        completeBarLoading();
      }
    },
    [isBarLoading],
  );

  return callRequest;
};

export default useApiCall;
