import React from 'react';
import { NavLink } from 'react-router-dom';

import logo from '../../images/logo-ysa.jpg';

import { NotFoundContainer } from './styles';

const NotFound: React.FC = () => (
  <NotFoundContainer>
    <h2>Página não encontrada.</h2>
    <NavLink to="/appointments">Ir para tela inicial</NavLink>
  </NotFoundContainer>
);

export default NotFound;
