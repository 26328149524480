import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  padding: 10px 0;
  width: 100%;
  z-index: 999;
  background: #740000;
`;

export const Content = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  > img {
    height: 80px;
  }

  button {
    margin-left: auto;
    background: none;
    border: none;

    svg {
      color: #fff;
    }
  }

  @media (max-width: 1200px) {
    margin: 0 35px;
  }
`;

export const LogoutButton = styled.button`
  margin-left: auto;
  background: none;
  border: none;
  outline: none;

  &:hover {
    cursor: pointer;
  }

  svg {
    color: #fff;
  }

  @media (max-width: 950px) {
    display: none;
  }
`;

export const SideBarButton = styled.button`
  margin-left: auto;
  background: none;
  border: none;
  display: none;
  outline: none;

  &:hover {
    cursor: pointer;
  }

  svg {
    color: #fff;
  }

  @media (max-width: 950px) {
    display: block;
  }
`;

export const LinkContainer = styled.div`
  margin-left: auto;

  a {
    margin-left: 70px;
    text-decoration: none;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.8);

    @media (max-width: 1200px) {
      margin: 0 35px;
    }
  }

  .white {
    color: rgba(255, 255, 255, 1);
    font-weight: bold;
  }

  @media (max-width: 950px) {
    display: none;
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  margin-left: 80px;

  img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    line-height: 24px;
    font-size: 1.4rem;

    span {
      color: #f4ede8;
    }

    // a {
    strong {
      text-decoration: none;
      color: #ff9000;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  @media (max-width: 950px) {
    display: none;
  }
`;
