import styled from 'styled-components';

import AuthImage from '../../../images/auth/auth01.png';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100vw;
`;

export const WrapperLogin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    z-index: 1;
    max-width: 40rem;
    position: sticky;
    width: 100%;
    margin-bottom: -10rem;
  }

  form {
    margin: 8rem 0;
    text-align: center;
    width: 100%;
    max-width: 34rem;

    @media (max-width: 400px) {
      padding: 0 1rem;
    }

    button {
      background-color: #750000;
    }

    h1 {
      margin-bottom: 2.4rem;
      font-size: 2.6rem;
    }

    a {
      color: #740000;
      display: block;
      margin-top: 2.4rem;
      text-decoration: none;
      transition: color 0.3s;
      font-size: 1.6rem;
    }
  }

  > a {
    color: #ff9000;
    display: block;
    margin-top: 2.4rem;
    text-decoration: none;
    transition: color 0.3s;
    display: flex;
    align-items: center;

    svg {
      margin-right: 1.6rem;
    }
  }
`;
