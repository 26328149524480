import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
`;

export const Header = styled.div`
  width: 100%;
  background: #c4c4c4;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  margin-top: 30px;

  h4 {
    color: black;
    font-size: 22px;
    line-height: 40px;
    font-weight: bold;
  }

  svg {
    color: black;
    font-size: 40px;
  }
`;

interface Props {
  maxHeight: string;
}

export const ContentContainer = styled.div<Props>`
  max-height: ${props => props.maxHeight};
  overflow: hidden;
  transition: all 0.3s ease;
  font-size: 17px;
`;
