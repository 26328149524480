import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  background: #c4c4c4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 5px 10px;
  margin-top: 15px;
  border-radius: 5px;

  strong {
    color: black;
    font-size: 16px;
    margin-top: 3px;
    font-weight: bold;
  }

  p {
    margin-top: 1px;
    font-size: 16px;
    margin-left: 20px;
    color: black !important;
  }
`;
