import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 5rem;
  padding: 20rem;

  .create-button {
    width: 89rem;
    height: 5rem;
    margin: 1rem auto;
    display: flex;
    justify-content: flex-end;
    a {
      border: none;
      outline: none;
      cursor: pointer;
      margin-bottom: 1rem;
      background: #c4c4c4;
      border-radius: 5px;
      width: 5%;
      padding: 4px 0;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 25px;
        color: black;
        margin: 0 auto;
      }
    }
  }
`;

export const UserComponent = styled.div`
  width: 90rem;
  height: 7rem;
  display: flex;
  margin: 1rem auto;
  justify-content: space-evenly;

  img {
    min-height: 7rem;
    min-width: 7rem;
    border-radius: 100%;
  }
  select {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 2.5rem;
    font-weight: 800;
  }
  span {
    width: 50rem;
    height: 100%;
    background: #c4c4c4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 1rem;
    color: black;
    align-items: center;
    font-size: 2.5rem;
    font-weight: 800;
    padding: 1.5rem;
    cursor: pointer;
  }
`;
