import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import Login from '../pages/Auth/Login';
import Register from '../pages/Auth/Register';
// import Store from '../pages/Panel/Store';
// import EditProduct from '../pages/Panel/Products/Edit';
// import CreateProduct from '../pages/Panel/Products/Create';
// import CreateProductContent from '../pages/Panel/Content/Create';
// import EditProductContent from '../pages/Panel/Content/Edit';
// import CreateCategory from '../pages/Panel/CreateCategory';
import Scheduling from '../pages/Panel/Scheduling/Index';
import Schedule from '../pages/Panel/Scheduling/Schedule';
import ForgotPassword from '../pages/Auth/ForgotPassword';
// import Content from '../pages/Panel/Content';
import PanelRoute from '../pages/Panel';
import { useAuth } from '../hooks/auth';
import NotFound from '../pages/NotFound';
import CreateUsers from '../pages/Panel/Users/CreateUser';
import ListUsers from '../pages/Panel/Users/ListUsers';

const Routes: React.FC = () => {
  const { user } = useAuth();

  const publicRoute = (Component: React.FC) =>
    user ? () => <Redirect to="/appointments" /> : Component;

  const privateRoute = (Component: React.FC) =>
    user ? Component : () => <Redirect to="/" />;

  return (
    <Switch>
      <Route exact path="/" component={publicRoute(Login)} />
      {/* <Route exact path="/registrar" component={publicRoute(Register)} /> */}
      <Route
        exact
        path="/esqueceu-a-senha"
        component={publicRoute(ForgotPassword)}
      />

      <PanelRoute
        exact
        path="/appointments"
        component={privateRoute(Scheduling)}
      />
      <PanelRoute exact path="/users" component={privateRoute(ListUsers)} />
      <PanelRoute
        exact
        path="/users/create"
        component={privateRoute(CreateUsers)}
      />
      <PanelRoute
        exact
        path="/users/edit"
        component={privateRoute(CreateUsers)}
      />
      <PanelRoute
        path="/appointments/schedule"
        component={privateRoute(Schedule)}
      />
      {/* <PanelRoute exact path="/conteudo" component={privateRoute(Content)} /> */}
      {/* <PanelRoute exact path="/loja" component={privateRoute(Store)} />
      <PanelRoute
        exact
        path="/loja/adicionar/produto"
        component={privateRoute(CreateProduct)}
      />
      <PanelRoute
        exact
        path="/loja/editar"
        component={privateRoute(EditProduct)}
      />
      <PanelRoute
        exact
        path="/loja/adicionar/categoria"
        component={privateRoute(CreateCategory)}
      />
      <PanelRoute
        exact
        path="/conteudo/adicionar"
        component={privateRoute(CreateProductContent)}
      />
      <PanelRoute
        exact
        path="/conteudo/editar/"
        component={privateRoute(EditProductContent)}
      /> */}

      <Route path="/404" component={privateRoute(NotFound)} />

      <Route path="*" component={() => <Redirect to="/404" />} />
    </Switch>
  );
};

export default Routes;
