import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import Header from '../../components/Header';

import { Container } from './styles';

const PanelRoute: React.FC<RouteProps> = ({
  component,
  path,
  exact,
}: RouteProps) => {
  return (
    <Container>
      <Header />
      <Route exact={exact} path={path} component={component} />
    </Container>
  );
};

export default PanelRoute;
