import React, { useEffect } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { SkeletonTheme } from 'react-loading-skeleton';
import GlobalStyle from './styles/global';

import 'react-day-picker/lib/style.css';
import Routes from './routes';
import AppProvider from './hooks';

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App: React.FC = () => (
  <SkeletonTheme color="#202020" highlightColor="#444">
    <BrowserRouter>
      <AppProvider>
        <GlobalStyle />
        <ScrollToTop />
        <Routes />
      </AppProvider>
    </BrowserRouter>
  </SkeletonTheme>
);

export default App;
