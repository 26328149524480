import * as yup from 'yup';

export const nameValidator = yup
  .string()
  .typeError('Nome inválido')
  .required('Nome é obrigatório')
  .matches(
    /^[A-ZÁ-Úa-zá-ú]{1,14}( [A-ZÁ-Úa-zá-ú]{1,14})+$/,
    'Nome deve conter duas palavras iniciadas com letras maiúsculas',
  );

export const emailValidator = yup
  .string()
  .typeError('Email inválido')
  .email('Email inválido')
  .required('Email é obrigatório');

export const emailConfirmationValidator = (prop: string) =>
  yup
    .string()
    .typeError('Email de confirmação inválido')
    .oneOf([yup.ref(prop)], 'Os emails não são iguais')
    .required('Email de confirmação é obrigatório');

export const passwordValidator = yup
  .string()
  .typeError('Senha inválida')
  .required('Senha é obrigatória')
  .min(8, 'Senha deve ter no mínimo 8 caracteres');

export const documentNumberValidator = yup
  .string()
  .typeError('CPF/CNPJ inválido')
  .required('CPF/CNPJ é obrigatório')
  .matches(
    /^(\d{3}\.\d{3}\.\d{3}-\d{2})|(\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2})$/,
    'CPF/CNPJ deve seguir a seguinte regra "XXX.XXX.XXX-XX"/"XX.XXX.XXX/XXXX-XX"',
  );

export const personalDocumentNumberValidator = yup
  .string()
  .typeError('CPF inválido')
  .required('CPF é obrigatório')
  .matches(
    /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
    'CPF deve seguir a seguinte regra "XXX.XXX.XXX-XX"',
  );

export const companyDocumentNumberValidator = yup
  .string()
  .typeError('CNPJ inválido')
  .required('CNPJ é um campo obrigatório')
  .matches(
    /^(\d{3}\.\d{3}\.\d{3}-\d{2})|(\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2})$/,
    'CNPJ deve seguir a seguinte regra "XX.XXX.XXX/XXXX-XX"',
  );

export const phoneNumberValidator = yup
  .string()
  .typeError('Telefone inválido')
  .required('Telefone é obrigatório')
  .matches(
    /^\(\d{2}\) \d{4,5}-\d{4}$/,
    'Telefone deve seguir a seguinte regra "(XX) XXXXX-XXXX',
  );

export const postalCodeValidator = yup
  .string()
  .typeError('CEP inválido')
  .required('CEP é obrigatório')
  .matches(/^\d{5}-\d{3}$/, 'CEP deve seguir a seguinte regra "XXXXXX-XXX"');
