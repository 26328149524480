import React from 'react';
import { StyledDayPicker } from './styles';

interface Props {
  onChangeDay: (day: Date) => void;
  onChangeMonth: (month: Date) => void;
  currentMonth: Date;
  currentDay: Date;
  fromMonth: Date;
  unavailableDays: Date[];
}

const AppointmentDayPicker: React.FC<Props> = ({
  onChangeDay,
  onChangeMonth,
  currentMonth,
  currentDay,
  fromMonth,
  unavailableDays,
}) => {
  return (
    <StyledDayPicker
      className=""
      weekdaysShort={['D', 'S', 'T', 'Q', 'Q', 'S', 'S']}
      fromMonth={fromMonth}
      month={currentMonth}
      disabledDays={[{ daysOfWeek: [0] }, ...unavailableDays]}
      modifiers={{
        available: { daysOfWeek: [1, 2, 3, 4, 5, 6] },
        highlighted: currentDay,
      }}
      months={[
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ]}
      onDayClick={onChangeDay}
      showOutsideDays={false}
      enableOutsideDaysClick={false}
      onMonthChange={onChangeMonth}
    />
  );
};

export default AppointmentDayPicker;
