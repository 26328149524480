import React from 'react';
import { Link } from 'react-router-dom';
import { FiMail, FiLock } from 'react-icons/fi';
import * as yup from 'yup';
import { Container, Content, WrapperLogin } from './styles';

import Input from '../../../components/Input';
import Button from '../../../components/Button';

import Logo from '../../../images/logo-ysa.jpg';
import { useAuth } from '../../../hooks/auth';
import {
  emailValidator,
  passwordValidator,
} from '../../../utils/yupValidators';
import { useToast } from '../../../hooks/toast';

const Login: React.FC = () => {
  const { signIn } = useAuth();
  const { addToast } = useToast();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;
    const email = form.querySelector<HTMLInputElement>('input[type=email]')
      ?.value as string;
    const password = form.querySelector<HTMLInputElement>(
      'input[type=password]',
    )?.value as string;

    try {
      const schema = yup.object().shape({
        email: emailValidator,
        password: passwordValidator,
      });
      await schema.validate({ email, password }, { abortEarly: false });

      try {
        await signIn({ email, password });
      } catch (error) {}
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description: `${error.errors[0]}`,
        });
      }
    }
  };
  return (
    <Container>
      <Content>
        <WrapperLogin>
          <img
            style={{ width: '20rem', marginBottom: '-6rem' }}
            src={Logo}
            alt="Logo - ysa"
          />

          <form onSubmit={handleSubmit}>
            <h1> Faça seu login </h1>
            <Input
              name="email"
              icon={FiMail}
              type="email"
              placeholder="E-mail"
            />

            <Input
              name="password"
              icon={FiLock}
              type="password"
              placeholder="Senha"
            />

            <Button textColor="white" type="submit">
              Entrar
            </Button>

            <Link to="/esqueceu-a-senha">Esqueci minha senha</Link>
          </form>

          {/* <Link to="/registrar">
            <FiLogIn />
            Criar conta
          </Link> */}
        </WrapperLogin>
      </Content>
    </Container>
  );
};

export default Login;
