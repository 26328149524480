import { format } from 'date-fns';
import React from 'react';
import { Container } from './styles';

interface Props {
  id: string;
  hour: Date;
  name: string;
  selectAppointmentItem: (id: string) => void;
}

const AppointmentItem: React.FC<Props> = ({
  id,
  hour,
  name,
  selectAppointmentItem,
}: Props) => (
  <Container onClick={() => selectAppointmentItem(id)}>
    <strong>{format(hour, 'HH:mm')}</strong>
    <p>{name}</p>
  </Container>
);

export default AppointmentItem;
